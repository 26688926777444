:root {
    --list-icon-size: 25px;
}

.WorkshopOverview .Horizontal {
    display: flex;
    width: 100%;
    margin-right: -30px;
}

.WorkshopOverview .Horizontal>div{
    align-self: flex-start;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 20px;    flex-wrap: nowrap;
}


.WorkshopOverview .Horizontal>div:last-child {
    margin-right: 0;
}


.WorkshopOverview .ModuleList {
    background-color: var(--color-lighter);
    border: 1px solid var(--color-light2);
    border-radius: 26px;
    padding: 0 50px;
    position: relative;
}

.WorkshopOverview .ModuleList:before {
    content: "";
    position: absolute;
    display: block;
    background-color: #000;
    width: 4px;
    border-radius: 2px;
    left: 62px;
    top: 10px;
    bottom: 10px;
    z-index: 0;
}

.WorkshopOverview .ModuleList li {
    position: relative;
    vertical-align: middle;
    font-size: 18px;
    color: var(--color-light2);
    margin: 30px 0;
}

.WorkshopOverview .ModuleList a {
    white-space: nowrap;
    color: var(--color-light2);
    text-decoration: none;
    line-height: var(--list-icon-size);
}

.WorkshopOverview .ModuleList li:before {
    z-index: 1;
    content: "";
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #000;
    width: var(--list-icon-size);
    height: var(--list-icon-size);
    background-color: #ffffff;
    margin-right: 20px;
    /*margin-bottom: 10px;*/
}

.WorkshopOverview .ModuleList li.IsCurrentModule a {
    color: var(--color-dark);
}

.WorkshopOverview .ModuleList li.IsCurrentModule:before {
    background-color: var(--color-light2);
}


@media only screen and (max-width: 800px) {
    .WorkshopOverview .Horizontal {
        display: block;
    }

    .WorkshopOverview .Horizontal>div {
        margin-bottom: 30px;
    }

}


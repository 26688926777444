.Modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 900;
}

.Modal.Hidden {
    display: none;
}

.Popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 477px;
    padding: 30px;
    background-color: var(--color-darkest);
    border-radius:  16px;
}

.Popup h1 {
    font-size: 20px;
    line-height: 100%;
    text-align: left;
    color: var(--color-light)
}

.Popup p {
    font-size: 14px;
    line-height: 125%;
    text-align: left;
    color: #ffffff;
    margin: 5px 0;
}

.Popup .ClosePopup {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: #ffffff;;
}

.Popup textarea {
    resize: none;
    width: 100%;
    height: 200px;
    margin: 15px 0;
}

.Popup .SelectWrapper {
    position: relative;
    margin: 15px 0;
}


.Popup .SelectWrapper svg {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 30px;
    height:30px;
}

.Popup select {
    width: 100%;
    height: 45px;
    font-size: 17px;
    appearance:none;
    text-indent: 15px;
}

.Modal-Form-Error {
    color: #cc0000;
    margin-top: -15px;
    margin-bottom: 15px;
    /*margin: 0;*/
    
}

.PopupButtonConfirm {
    color: #ffffff;
    background-color: var(--color-light);
    border-radius: 3px;
    font-size: 20px;
    text-decoration: none;
    padding: 15px 50px;
    text-align: center;
    align-self: center;
}

.Loading-Overlay {
    background-color: #ffffff;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 1000;
}


.AdminWorkshopOverview .Section-Content > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.AdminWorkshopOverview .Section-Content > ul > li {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 30px;
}

.Login {
    background-image: linear-gradient(#096067, #00323b);
    min-height: 100vh;
}

.LoginHeader {
    display: flex;
    align-items: center;
}

.LoginHeader img {
    margin: 20px;
}

.LoginHeader h1 {
    font-weight: normal;
    font-size: 36px;
    color: var(--color-light);
}

.Login form {
    margin: 0 auto;
    max-width: 600px;
    padding: 10px;
}

.Login form h1 {
    color: var(--color-accent);
    font-size: 60px;
    font-weight: bold;
    margin: 30px 0;
}

.Login form input[type="text"], form input[type="password"] {
    display: block;
    width: 100%;
    border: none;
    background: none;
    color: var(--color-light);
    border-bottom: 2px solid var(--color-light);
    border-radius: 2px;
    font-size: 24px;
    outline: none;
    margin-top: 20px;
    padding: 5px;
}

.Login form button[type="submit"] {
    font-size: 32px;
    background: none;

    color: var(--color-light);
    border: 2px solid var(--color-light);
    border-radius: 7px;

    width: 100%;
    padding: 30px;
    margin-top: 20px;
}

.Login form button[type="submit"].Loading {
    padding: 0;
}

.Login form button[type="submit"] .Login-Button-Loading-Animation {
    display: none;
}

.Login form button[type="submit"].Loading .Login-Button-Loading-Animation {
    display: block;
}

.Login form button[type="submit"].Loading .Login-Button-Text {
    display: none;
}

.Login form p, .Login form a {
    display: block;
    color: var(--color-light);
    margin: 7px 0;
    font-size: 16px;
    height: 18px;
}


.Message {
    color: var(--color-accent);
    max-width: 600px;
    margin: 0 auto;
}
.Message h1 {
    color: var(--color-accent);
    font-size: 60px;
    font-weight: bold;
    margin: 30px 0;
}
.Message p {
    color: var(--color-light);
}


@media only screen and (max-width: 660px) {

    .LoginHeader h1 {
        font-size:24px;
    }

    .Login form h1 {
        color: var(--color-accent);
        font-size: 30px;
        font-weight: bold;
        margin: 15px 0;
    }
    
    .Login form button[type="submit"]
    {
        padding: 15px;
    }
}



.ModuleBrowser {

}

.ModuleBrowser hr {
    clear: both;
}

.ModuleBrowser .Files {
    display: flex;
    flex-wrap: wrap;
    color: var(--color-dark);
    text-align: center;
}

.ModuleBrowser .File {
    margin: 30px;
    position: relative;
}


.ModuleBrowser .File a {
    /*vertical-align: middle;*/
    /*font-size: 20px;*/
    color: var(--color-dark);
    text-decoration: none;
}

.ModuleBrowser .Thumbnail {
    border: 2px solid var(--color-gray);
    background-color: #ffffff;
    border-radius: 6px;
    width: 140px;
    height: 140px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12.5px;
    margin-left: 12.5px;
    margin-bottom: 7px;
}

.ModuleBrowser .Thumbnail img {
    width: 140px;
    height: 140px;
}

.ModuleBrowser .Thumbnail .DirectoryIcon {
    margin: 40px;
}

.ModuleBrowser .Filename {
    font-size: 16px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ModuleBrowser .Filename svg {
    width: 25px;
    vertical-align: middle;
    margin-right: 10px;
}

.ModuleBrowser .QRCode .Thumbnail {
    margin-top: 0;
    margin-left: 0;
    width: 165px;
    height: 165px;
}

.ModuleBrowser .QRCodeTitle {
    font-weight: bold;
    font-size: 20px;
}

.ModuleBrowser .QRCodeDescription {
    font-size: 14px;
    max-width: 165px;
}

.MobileLanding {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MobileLanding .Top-Button {
    margin: 0;
    padding: 0 100px;
    width: auto;
    float: none;
}

.MobileLanding p {
    font-size: 24px;
    margin-top: 15px;
    color: var(--color-light2);
    text-align: center;
}
@import "normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
    --color-light: #00cdd6;
    --color-light2: #00a8af;
    --color-lighter: #daeef5;
    --color-dark: #2e838a;
    --color-darker: #176073;
    --color-darkest: #00323b;
    --color-accent: #ffba52;
    --color-black: #333333;
    --color-gray: #cecece;
    --mobile-width: 660px;
}

html, body, #root, #root > div {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

hr {
    border: none;
    border-top: 2px solid var(--color-accent);
    margin: 15px 0;
    clear: both;
}

* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

a, button {
    cursor: pointer;
}

.Menu {
    border: 3px solid var(--color-light); /* becomes 2px by -1px margin below */
}

.Menu.Hidden {
    display: none;
}

.Menu ul {
    margin: -1px; /* hack for ugly rendering at not-100% - zoomlevels */
}

.Menu li {
    line-height: 48px;
    height: 50px;
    text-align: center;
    border-bottom: 3px solid var(--color-light);
}

.Menu li:last-child {
    border-bottom: none;
    line-height: 50px;
}

.Menu li > a {
    display: block;
    color: var(--color-darker);
    text-decoration: none;
    background-color: #ffffff;
}

.Menu li > a:hover {
    background-color: var(--color-light);
}


.SmallButton {
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: var(--color-dark);
    font-size: 20px;
    text-decoration: none;
    border: 2px solid var(--color-gray);
    border-radius: 16px;
    padding: 0 20px;
    line-height: 45px;
    width: 250px;
    margin: 15px 35px;
    margin-right: 0;
}

.SmallButton span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.SmallButton svg {
    flex-shrink: 0;
    height: 24px;
    margin-right: 10px
}

.ContextMenuButton {
    position: absolute;
    z-index: 1;
    color: var(--color-black);
    right: -35px;
    width: 35px;
    height: 35px;
}

.ContextMenu {
    position: absolute;
    z-index: 2;
    right: -35px;
    width: 150px;
}

.ContextMenuButton-Center {
    top: 50%;
    transform: translate(0, -50%);
}

.ContextMenu-Center {
    top: calc(50% + 15px);
}

.ContextMenuButton-Top {
    top: 10px;
}

.ContextMenu-Top {
    top: 45px;
}

.PageDescription {
    color: #8b8b8b;
    font-size: 20px;
    margin-top: 20px;
}

.Top-Button {
    display: block;
    background-color: #2AB6BC;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    float: right;
    border-radius: 13px;
    text-decoration: none;
    color: #00323B;
    margin-bottom: 10px;
    margin-left: 10px;
}

.Top-Button svg {
    height: 40px;
    vertical-align: middle;
}


.Add-Button {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    margin: 0 35px;
}

.Add-Button svg {
    padding: 4px;
    width: 35px;
    height: 35px;
    color: #ffffff;
    border-radius: 50%;
    background-color: var(--color-dark);
    margin-bottom: 10px;
}

.Add-Button span {
    font-size: 16px;
    color: var(--color-dark);
    text-decoration: none;
}


.DiamondList {
    color: var(--color-dark);
    font-size: 20px;
    margin-bottom: 15px;

}
.DiamondList li {
    vertical-align:middle;
    margin-bottom: 5px;
}

.DiamondList li > svg {
    height: 25px;
    vertical-align:middle;
}

.Section {
    border: 2px solid var(--color-gray);
    border-radius: 18px;
    margin-bottom: 40px;
}

.Section-Header {
    padding: 10px;
    border-bottom: 2px solid var(--color-accent);
    border-top-right-radius: 16px;
    background: url("images/section-header.png");
    background-repeat: no-repeat;
    background-position: top right;
}

.Section-Header h2 {
    font-size: 28px;
    bottom: 7px;
}

.Section-Header p {
    font-size: 20px;
}

.Section-Content {
    padding: 25px 10px;
    min-height: 30px;
}


.Mobile-Only {
    display: none;
}

.Desktop-Only {
    display: block;
}


@media only screen and (max-width: 660px) {
    .Mobile-Only {
        display: block;
    }

    .Desktop-Only {
        display: none;
    }
}
.BreadCrumbs {
    display: block;
    background: var(--color-lighter);
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}

.BreadCrumbs li {
    display: inline-block;
}

.BreadCrumbs li:after {
    content: "/";
    display: inline-block;
    margin: 0 15px;
}

/*.BreadCrumbs li:last-child:after {
    content: "";
}*/


.BreadCrumbs a {
    font-weight: 700;  
    color: var(--color-dark);
    text-decoration: none;
}
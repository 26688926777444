:root {
    --users-width: 300px;
}

.AdminWorkshopEditor {
    margin-right: var(--users-width);

}

.AdminWorkshopEditor .Modules {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.AdminWorkshopEditor .Modules .Module {
    position: relative;
    margin-right: 30px;
}

.AdminWorkshopEditor .Users {
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: var(--users-width);
    border-left: 2px solid var(--color-light);
    padding: 15px;
}

.AdminWorkshopEditor .Users h1 {
    margin-bottom: 5px;
    font-size: 18px;
    color: var(--color-dark);
}

.AdminWorkshopEditor .Users .AddUser svg {
    float:right;
    height: 18px;
    vertical-align: middle;
}


.AdminWorkshopEditor .Users h2 {
    font-size: 16px;
    color: var(--color-light);
}

.AdminWorkshopEditor .Users ul {
    margin-bottom: 20px;
}

.AdminWorkshopEditor .Users li {
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: center;
}


.AdminWorkshopEditor .Users .UserIcon {
    vertical-align: middle;
    color: var(--color-accent);
    width: 16px;
    min-width: 16px;
    margin-right: 5px;
}

.AdminWorkshopEditor .Users .UserName {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AdminWorkshopEditor .Users .DeleteButton {
    align-self: end;
}

.AdminWorkshopEditor .Users .DeleteButton svg {
    vertical-align: middle;
    color: var(--color-gray);
    height: 16px;
}

.Current-Module {
    background-color: var(--color-dark);
    color: var(--color-accent);
    border-color: var(--color-accent);
}

.SendEmailsWrapper {
    display: flex;
}


.SendEmailsButton {
    margin: 0 auto;
    margin-top: 15px;
    color: #ffffff;
    background-color: var(--color-light);
    border-radius: 3px;
    font-size: 20px;
    text-decoration: none;
    padding: 8px 25px;
    text-align: center;
    align-self: center;
}
:root {
    --topbar-height: 90px;
    --sidebar-width: 100px;
}

#root {
    background-image: linear-gradient(#30838A, #02A8AF);
}

.Container {
    display: flex;
    flex-direction: column;
    color: var(--color-black);
    min-height: 100vh;
}

.Header {
    width: 100vw;
    height: var(--topbar-height);
    background: url("../../images/header-background.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Header-Logo {
    margin-left: 25px;
}

.Header-Mobile-Menu {
    display: none;
}

.Header-Mobile-Menu-Item a {
    display: block;
    color: #ffffff;
    width: 40px;
    margin: 0 7px;
}

.Header-Menu-Link {
    margin-right: 25px;
}

.Header-Menu-Link > a {
    text-decoration: none;
    color: var(--color-dark);
}

.Header-Menu-Link-Icon {
    padding: 4px;
    width: 50px;
    height: 50px;
    color: #ffffff;
    border: 1px solid var(--color-dark);
    border-radius: 50%;
    background-color: var(--color-darker);
}

.Header-Menu-Link-Text {
    font-size: 16px;
    text-decoration: none;
}

.Header-Menu {
    position: absolute;
    width: 200px;
    right: 30px;
    top: calc(var(--topbar-height) - 2px);
    z-index: 1;
}

.Header-Bottom-Border {
    position: absolute;
    background: var(--color-accent);
    height: 2px;
    align-self: end;
    left: calc(var(--sidebar-width) - 2px);
    right: 0;
}

.Main {
    display: table;
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

.Sidebar {
    display: table-cell;
    width: var(--sidebar-width);
    border-right: 2px solid var(--color-accent);
    position: relative;
}

.Sidebar-Menu {
    width: 100%;
    color: var(--color-accent);
}

.Sidebar-Menu a {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--color-accent);
    text-decoration: none;
    height: 100px; 
}

.Sidebar-Menu svg {
    display: inline-block;
    width: 25px;
    margin: 10px 0;
}

.Sidebar-Menu span {
    flex-grow: 1;
    justify-content: center;
    font-size: 18px;
}

.Sidebar-Help {
    position: absolute;;
    margin: 0 auto;
    bottom: 30px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0);
}

.Sidebar-Help a {
    display: block;
    text-align: center;
    vertical-align: middle;
    font-size: 32px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-decoration: none;
    border: 1px solid #ffffff;
    color: #ffffff;
    border-radius: 50%;
}


.Content {
    display: table-cell;
    padding: 10px 30px;
    flex: 1 0 auto;
    background: #F8F8FF;
    position: relative;
}

.Content-Overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #F8F8FF;
    z-index: 1100;
}

.Content-Error {
    margin: 30px;
    color: red;
}

@media only screen and (max-width: 660px) {
    .Header {
        background-image: none;
    }

    .Sidebar {
        display: none;
    }

    .Content {
        left: 0;
    }

    .Header-Bottom-Border {
        display: none;
    }

    .Header-Menu-Link-Text {
        color: #ffffff;
    }

    .Header-Mobile-Menu {
        display: flex;
    }
}

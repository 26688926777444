.ImageViewer {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ImageViewer {
    background-image: linear-gradient(rgba(52, 70, 77, 0.89), rgba(103, 138, 139, 0.76));
}


.ImageViewer .CurrentImage {
    
}